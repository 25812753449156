<template>
  <section class="hero section" :style="sectionBackground">
    <div class="container">
      <div class="hero__content">
        <h1 class="hero__title">{{ title }}</h1>
        <p class="hero__description">{{ description }}</p>
        <a class="hero__button button button--primary" href="#contact-form">{{ button.text }}</a>
      </div>
    </div>
  </section>
</template>
  
  <script>
  export default {
    name: 'Hero',
    data() {
      return {
        title: 'Аутсорсинг услуг для вашего бизнеса',
        description: 'Работник торгового зала, грузчик, кассир, разнорабочий, комплектовщик, фасовщик, стикеровкщик, комплектовщик, мойщик, уборщик, обслуживание гостиничных и ресторанных комплексов, промоутер',
        button: {
          text: 'Оставить заявку'
        }
      }
    },
    computed: {
      sectionBackground() {
        let image = require('@/assets/images/hero_bg.jpeg');
        return `background-image: url(${image});`;
      }
    }
  }
  </script>
  
<style scoped lang="scss">
  .hero {
    height: calc(100vh - 96px);
    width: 100%;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: rgba(0, 0, 0, 0.6);
    background-blend-mode: multiply;

    &__content {
      width: 100%;

      @include media-min(l){
        width: 67%;
      }
    }

    &__title {
      font-size: var(--font-size-h1);
      margin-bottom: var(--padding-large);
      color: var(--color-white);
      text-shadow: 0px 15px 25px rgba(0, 0, 0, 0.5);
    }

    &__description {
      font-size: clamp(var(--font-size-text), 2vw, var(--font-size-text-large));
      font-family: var(--font-family-heading);
      line-height: 1.5;
      margin-bottom: var(--padding-large);
      color: var(--color-white-opacity);
    }

  }
</style>
  