<template>
    <div class="header">
        <div class="container header__container">
            <div class="header__logo">
                <h3 class="header__logo-text">{{ logo.text }}</h3>
            </div>
            <div class="header__menu">
                <ul class="header__menu-list">
                    <li v-for="item in menu" :key="item.id" class="header__menu-item">
                        <a :href="`#${item.id}`" class="header__menu-link">{{ item.text }}</a>
                    </li>
                </ul>
                <a class="header__menu-button button button--secondary" href="#contact-form">{{ button.text }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    data(){
        return {
            logo: {
                text: 'GENESIS'
            },
            menu: [
                {
                    id: 'services',
                    text: 'Услуги'
                },
                {
                    id: 'prices',
                    text: 'Цены'
                },
                {
                    id: 'about',
                    text: 'О компании'
                },
                {
                    id: 'contact',
                    text: 'Контакты'
                }
            ],
            button: {
                text: 'Заказать услугу'
            }
        }
    }
}
</script>

<style scoped lang="scss">

.header {
    background-color: var(--color-secondary);
    height: 96px;

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        @include media-min(s){
            justify-content: space-between;
        }
    }

    &__logo {}

    &__logo-text {
        font-size: clamp(2rem, 3vw, 2.5rem);
        font-weight: bold;
        color: var(--color-primary);
        white-space: nowrap;
    }

    &__menu {
        display: flex;
        gap: 24px;
    }

    &__menu-list {
        display: none;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
        gap: 16px;

        @include media-min(m){
            display: flex;
        }
    }

    &__menu-item {}

    &__menu-link {
        font-size: 16px;
        line-height: 24px;
        color: var(--color-white);
        text-decoration: none;

        &:hover {
            color: var(--color-primary);
        }
    }

    &__menu-button {
        display: none;

        @include media-min(s){
            display: block;
        }
    }
}

</style>