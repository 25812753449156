<template>
  <section id="contact" class="section section--secondary details">
    <div class="container">
      <h2 class="section__title details__title">{{ sectionTitle }}</h2>
      <h3 class="details__subtitle">{{ sectionSubtitle }}</h3>
      <div class="details__content">
        <div class="details__text">
          <DetailItem
              v-for="(item, index) in contactItems"
              :key="index"
              :title="item.title"
              :details="item.details"
            />
        </div>
      </div>
    </div>
  </section>
</template>
  
  <script>
  import DetailItem from '@/components/DetailItem.vue';
  
  export default {
    components: {
      DetailItem,
    },
    data() {
      return {
        sectionTitle: 'Контакты',
        sectionSubtitle: 'ООО "Генезис"',
        contactItems: [
          {
            title: 'Адрес',
            details: 'Ростов-на-Дону, улица Очаковская, дом 39, офис 19',
          },
          {
            title: 'Телефон',
            details: '8 (989) 727 08 88',
          },
          {
            title: 'Email',
            details: 'zakaz@genesis-rus.com',
          },
        ],
        backgroundStyle: {
          background: '#111111',
        },
      };
    },
  };
  </script>
  
<style scoped lang="scss">

.details {

  &__subtitle {
    font-size: var(--font-size-h4);
    color: var(--color-primary);
  }

  &__text {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    & > * {
      width: 100%;

      @include media-min(m){
        width: calc(50% - 0.5rem);
      }

    }
  }
}

</style>
  