<template>
  <section id="prices" class="prices section section--tertiary">
    <div class="prices__content container">
      <h3 class="prices__title section__title">{{ sectionTitle }}</h3>
      <div class="prices__list">
        <priceItem v-for="price in prices" :key="price.title" :price="price" />
      </div>
    </div>
  </section>
  </template>
  
  <script>
  import PriceItem from '@/components/PriceItem.vue';
  
  export default {
    name: 'Prices',
    components: {
      PriceItem
    },
    data() {
      return {
        sectionTitle: 'Стоимость услуг',
        prices: [
            { title: 'Работник торгового зала', cost: 'от 180 руб./час' },
            { title: 'Стикеровщик', cost: 'от 180 руб./час' },
            { title: 'Грузчик', cost: 'от 190 руб./час' },
            { title: 'Фасовщик', cost: 'от 180 руб./час' },
            { title: 'Кассир', cost: 'от 200 руб./час' },
            { title: 'Комплектовщик', cost: 'от 240 руб./час' },
            { title: 'Мойщик', cost: 'от 190 руб./час' },
            { title: 'Промоутер', cost: 'от 200 руб./час' },
            { title: 'Упаковщик', cost: 'от 180 руб./час' },
            { title: 'Водитель погрузчика', cost: 'от 360 руб./час' },
            { title: 'Разнорабочий', cost: 'от 190 руб./час' },
            { title: 'Уборщик', cost: 'от 190 руб./час' },
            { title: 'Горничная', cost: 'от 240 руб./час' },
            { title: 'Официант', cost: 'от 380 руб./час' },
            { title: 'Продавец', cost: 'от 180 руб./час' }
        ]
      };
    }
  }
  </script>
  
<style scoped lang="scss">

.prices {
  background-color: var(--color-tertiary);
  text-align: center;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.5rem;
    margin-bottom: var(--padding-large);
  }
}

</style>
  