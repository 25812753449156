<template>
  <section id="about" class="section section--primary about">
    <div class="about__image section__image" :style="imageStyle"></div>
    <div class="container">
      <h2 class="section__title about__title">{{ title }}</h2>
      <div class="about__content">
        <h3 class="about__subtitle">{{ subtitle }}</h3>
        <p v-for="(paragraph, index) in paragraphs" :key="index">{{ paragraph }}</p>
      </div>
    </div>
  </section>
</template>
  
<script>
  export default {
    data() {
      return {
        title: 'О компании',
        subtitle: 'Компания Генезис оказывает широкий комплекс услуг в сфере аутсорсинга.',
        paragraphs: [
          'Опытный коллектив компании делает ее самым динамичным игроком на рынке РФ. В компании Генезис работают высококлассные специалисты с богатым опытом работы в данной сфере. Грамотный и высоко ответственный подход к выполнению своей работы - это главные принципы оказания услуг аутсорсинговой компании Генезис.',
          'Выбрав нас однажды, вы будете готовы обращаться к нам снова и снова, зная что скорость, качество и надежность - все то, что является нашими приоритетами в работе.'
        ]
      };
    },
    computed: {
      imageStyle() {
        let image = require('@/assets/images/about_bg.jpeg');
        return {
          backgroundImage: `url(${image})`
        };
      }
    }
  }
</script>
  
<style scoped lang="scss">



.about {
  position: relative;

  &__image {
  display: none;

  @include media-min(m){
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(50% - 2rem);
    background-size: cover;
    background-position: center;
  }
}

  &__title {
    text-align: left;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--padding-small);
    margin-bottom: var(--padding-large);

    @include media-min(m){
      width: calc(50% - 2rem);
    }
  }

  &__content {

    p {
      font-size: var(--font-size-text-base);
      font-weight: 300;
    }

    p:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__subtitle {
    font-size: var(--font-size-h4);
    line-height: 1.2;
    margin-bottom: 1rem;
    color: var(--color-secondary);
  }
}

</style>
  